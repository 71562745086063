import {Link, useLocation} from "react-router-dom";
import {getBackgroundColor} from "./menu-utils";
import MBH_Logga_270522 from "../img/MBH_Logga_270522.svg";

interface UrlLinkProps {
  url: any,
  title: string,
}

const UrlLink = (props: UrlLinkProps) => {
  const {url, title} = props
  const location = useLocation()

  const isRoot = location.pathname === "/" && url === "/events"
  const isActive = location.pathname === url || isRoot
  return (
    <Link to={url} className={isActive ? "active-nav" : ""}>{title}</Link>
  )
}

export const DesktopMenu = () => {
  const location = useLocation()
  return (
    <header className={`mbh-header ${getBackgroundColor(location)}`}>
      <div className="mbh-header-wrapper">
        <div className="header-title">

          <Link to="/">
            <img src={MBH_Logga_270522}/>
          </Link>

        </div>
        <nav className="header-nav">
          <UrlLink url="/events" title="Verksamhet"/>
          <UrlLink url="/cinema" title="Biograf"/>
          <UrlLink url="/bokhandel" title="Bokhandel"/>
          <UrlLink url="/about" title="Om oss"/>
          <UrlLink url="/rent" title="Hyr lokal"/>
          {/*<UrlLink url="/butik" title="Butik"/>*/}
        </nav>
      </div>
    </header>
  )
}
