import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {apiUrl, useWindowDimensions} from "../App";
import remarkGfm from "remark-gfm";
import rehypeSlug from "rehype-slug";
import ReactMarkdown from "react-markdown";
import {ScrollToTopOnMount} from "./events";

interface IStoreItem {
    title: string,
    category: string,
    pages: number,
    isbn: string,
    slug: string,
    description: string,
    author: string,
    editor: string,
    price: number,
    published: boolean,
    image: string,
}

/* One individual item on the grid */
const StoreGridItem = (props: any) => {
    const {item} = props
    const url = `/bokhandel/${item?.slug}`
    console.log("item", item)
    return (
        <article className="shop-item-column">
            <div className="shop-item-column-image">
                <Link to={url}>
                    <img src={item?.image} style={{width: "100%"}}/>
                </Link>
            </div>
            <div className="shop-item-column-content">
                <h2>&nbsp;</h2>
                <h2>{item?.author}</h2>
                <h1>
                    <Link to={url}>{item.title}</Link>
                </h1>
                <h2>&nbsp;</h2>
                <h2>{item?.category}</h2>
                <h2>{item?.pages} sidor</h2>
                <h2>ISBN: {item?.isbn}</h2>
                <h2>&nbsp;</h2>
                <h2>{item?.price} :-</h2>
            </div>

        </article>
    )
}

/**
 * View that shows a grid of available store items
 * @param props
 * @constructor
 */
export const StoreItemElement = (props: any) => {
    const {item} = props
    return (
        item ? <StoreGridItem item={item}/> : null
    )
}

export const StoreItemDetail = (props: any) => {
    const {slug} = useParams()
    const [item, setItem] = useState<IStoreItem | null>(null)

    const {width} = useWindowDimensions()
    const widthThreshold = 1024
    const isMobile = width <= widthThreshold

    useEffect(() => {
        const baseUrl = apiUrl()
        fetch(`${baseUrl}/api/store/${slug}/`)
            .then(response => response.json())
            .then(setItem)
            .catch(error => console.warn(error))
    }, [])

    const emailContent = (item: IStoreItem) => {
        return `mailto:info@medborgarhuset.se?subject=Bokbeställning Hej! Jag vill beställa\n${item.title}`
    }
    return (
        item ?
            <>
                <ScrollToTopOnMount/>
                <div className="breadcrumbs">
                    <Link to={"/bokhandel"}>Bokhandel &gt; {item?.title}</Link>
                </div>

                <main>
                    <section className="upcoming-events">
                        <article>
                            <div className={`text-content article-center-content wide-margin`}>
                                <img src={item.image} className="shop-item-image" alt={item.title}/>

                                <h1 className={`${isMobile ? "headline-overflow" : ""}`}><Link
                                    to={`/event/${item?.slug}`}>{item?.title}</Link></h1>
                                <h2>{item.category}</h2>
                                <h2>{item.pages} sidor</h2>
                                <h2>ISBN: {item.isbn}</h2>
                                <h2>{item.price} :-</h2>

                                <br/>
                                <br/>

                                {
                                    item?.description
                                        ? <ReactMarkdown children={item.description} remarkPlugins={[remarkGfm]}/>
                                        : null
                                }
                            </div>
                        </article>
                    </section>
                </main>
            </>
            : null
    )
}

/**
 * The main store page
 * @param props
 * @constructor
 */
export const Store = (props: any) => {

    const [items, setItems] = useState<IStoreItem[] | null>(null)

    useEffect(() => {
        const baseUrl = apiUrl()
        fetch(`${baseUrl}/api/store/`)
            .then(response => response.json())
            .then(data => data.results)
            .then(setItems)
    }, [])

    return (
        <main>
            <section className="">
                <div className="article-center-content store-grid-or-block">
                    {
                        items?.map((item: IStoreItem) => {
                                return (<StoreItemElement key={item.isbn} item={item}/>)
                            }
                        )
                    }
                </div>
            </section>
        </main>
    )
}
